import { ref, watch, computed } from '@vue/composition-api'
import store from '@/store'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { useI18n } from '@core/utils/utils'

export default function useClientsList() {
  const toast = useToast()
  const { t } = useI18n()
  const refClientListTable = ref(null)

  // Table Handlers
  const tableColumns = [
    { key: 'id', label: '#', sortable: true },
    { key: 'name', label: t('client'), sortable: true },
    { key: 'email', label: t('Email'), sortable: true },
    { key: 'cpv_profiles', sortable: false, label: t('Tender Profile') },
    { key: 'is_active', sortable: true, label: t('status') },
    { key: 'is_test', sortable: true, label: t('Test Account') },
    { key: 'subscription_expires_at', sortable: true, label: t('Subscription expires') },
    { key: 'actions', label: t('actions') },
  ]
  const perPage = ref(10)
  const totalClients = ref(0)
  const currentPage = ref(1)
  const perPageOptions = [10, 25, 50, 100]
  const searchQuery = ref('')
  const sortBy = ref('id')
  const isSortDirDesc = ref(true)
  const statusFilter = ref(null)
  const profileFilter = ref(null)

  const dataMeta = computed(() => {
    const localItemsCount = refClientListTable.value ? refClientListTable.value.localItems.length : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalClients.value,
    }
  })

  const refetchData = () => {
    refClientListTable.value.refresh()
  }

  watch([currentPage, perPage, searchQuery, statusFilter, profileFilter], () => {
    refetchData()
  })

  const fetchClients = (ctx, callback) => {
    store
      .dispatch('clients/fetchClients', {
        q: searchQuery.value,
        perPage: perPage.value,
        page: currentPage.value,
        sortBy: sortBy.value,
        sortDesc: isSortDirDesc.value,
        status: statusFilter.value,
        profile: profileFilter.value,
      })
      .then(response => {
        const { data: clients, total } = response.data

        callback(clients)
        totalClients.value = total
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error fetching clients list',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  const resolveUserStatusVariant = status => {
    if (status === 'active') return 'success'
    if (status === 'inactive') return 'secondary'

    return 'primary'
  }

  return {
    fetchClients,
    tableColumns,
    perPage,
    currentPage,
    totalClients,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refClientListTable,

    resolveUserStatusVariant,
    refetchData,

    // Extra Filters
    statusFilter,
    profileFilter,
  }
}
