<template>
  <b-sidebar
    id="add-new-user-sidebar"
    :visible="isAddNewUserSidebarActive"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
    @hidden="resetForm"
    @change="(val) => $emit('update:is-add-new-user-sidebar-active', val)"
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5 class="mb-0">
          {{ $t('Add New Client') }}
        </h5>

        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />

      </div>

      <!-- BODY -->
      <validation-observer
        #default="{ handleSubmit }"
        ref="refFormObserver"
      >
        <!-- Form -->
        <b-form
          class="p-2"
          @submit.prevent="handleSubmit(onSubmit)"
          @reset.prevent="resetForm"
        >

          <!-- Name -->
          <validation-provider
            #default="validationContext"
            :name="$t('Name')"
            rules="required"
          >
            <b-form-group
              :label="$t('Name')"
              label-for="name"
            >
              <b-form-input
                id="name"
                v-model="clientData.name"
                autofocus
                :state="getValidationState(validationContext)"
                trim
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Registration number -->
          <validation-provider
            #default="validationContext"
            :name="$t('Registration number')"
            rules="alpha-num"
          >
            <b-form-group
              :label="$t('Registration number')"
              label-for="registrationNumber"
            >
              <b-form-input
                id="registrationNumber"
                v-model="clientData.registration_number"
                :state="getValidationState(validationContext)"
                trim
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- VAT -->
          <validation-provider
            #default="validationContext"
            :name="$t('VAT')"
            rules="alpha-num"
          >
            <b-form-group
              :label="$t('VAT')"
              label-for="vat"
            >
              <b-form-input
                id="vat"
                v-model="clientData.vat"
                :state="getValidationState(validationContext)"
                trim
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Tax payer -->
          <b-form-group
            :label="$t('Tax payer')"
            label-for="is-taxpayer"
          >
            <b-form-checkbox
              v-model="clientData.is_taxpayer"
              switch
              inline
            />
          </b-form-group>

          <!-- Bank account number -->
          <validation-provider
            #default="validationContext"
            :name="$t('Bank account')"
            rules="alpha-num"
          >
            <b-form-group
              :label="$t('Bank account')"
              label-for="bankAccount"
            >
              <b-form-input
                id="bankAccount"
                v-model="clientData.bank_account"
                :state="getValidationState(validationContext)"
                trim
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <h4 class="mb-1 mt-1">
            {{ $t('Address') }}:
          </h4>

          <!-- Address -->
          <validation-provider
            #default="validationContext"
            :name="$t('Address')"
          >
            <b-form-group
              :label="$t('Address')"
              label-for="address"
            >
              <b-form-input
                id="address"
                v-model="clientData.address"
                :state="getValidationState(validationContext)"
                trim
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Postcode -->
          <validation-provider
            #default="validationContext"
            :name="$t('Postcode')"
          >
            <b-form-group
              :label="$t('Postcode')"
              label-for="postcode"
            >
              <b-form-input
                id="postcode"
                v-model="clientData.post_code"
                :state="getValidationState(validationContext)"
                trim
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- City -->
          <validation-provider
            #default="validationContext"
            :name="$t('City')"
          >
            <b-form-group
              :label="$t('City')"
              label-for="city"
            >
              <b-form-input
                id="city"
                v-model="clientData.city"
                :state="getValidationState(validationContext)"
                trim
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Country -->
          <validation-provider
            #default="validationContext"
            :name="$t('Country')"
          >
            <b-form-group
              :label="$t('Country')"
              label-for="country"
              :state="getValidationState(validationContext)"
            >
              <v-select
                v-model="clientData.country"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="countries"
                :clearable="false"
                input-id="country"
              />
              <b-form-invalid-feedback :state="getValidationState(validationContext)">
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <h4 class="mb-1 mt-1">
            {{ $t('Contact data') }}:
          </h4>

          <!-- Contact person -->
          <b-form-group
            :label="$t('Contact person')"
            label-for="contact-person"
          >
            <b-form-input
              id="contact-person"
              v-model="clientData.contact_person"
              trim
            />
          </b-form-group>

          <!-- Phone -->
          <validation-provider
            #default="validationContext"
            :name="$t('Phone')"
          >
            <b-form-group
              :label="$t('Phone')"
              label-for="phone"
            >
              <b-form-input
                id="phone"
                v-model="clientData.phone"
                :state="getValidationState(validationContext)"
                trim
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Email -->
          <validation-provider
            #default="validationContext"
            :name="$t('Email')"
            rules="required|email"
          >
            <b-form-group
              :label="$t('Email')"
              label-for="email"
            >
              <b-form-input
                id="email"
                v-model="clientData.email"
                :state="getValidationState(validationContext)"
                trim
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Verified -->
          <b-form-group
            :label="$t('Verified')"
            label-for="is-verified"
          >
            <b-form-checkbox
              id="is-verified"
              v-model="clientData.is_verified"
              switch
              inline
              value="1"
              unchecked-value="0"
            />
          </b-form-group>

          <!-- Active -->
          <b-form-group
            :label="$t('Active')"
            label-for="is-active"
          >
            <b-form-checkbox
              id="is-active"
              v-model="clientData.is_active"
              switch
              inline
              value="1"
              unchecked-value="0"
            />
          </b-form-group>

          <!-- Test account -->
          <b-form-group
            :label="$t('Test account')"
            label-for="is-test"
          >
            <b-form-checkbox
              v-model="clientData.is_test"
              switch
              inline
              @change="handleIsTestAccountChange($event)"
            />
          </b-form-group>

          <!-- Subscription expires -->
          <b-form-group
            :label="$t('Subscription expires')"
            label-for="subscription-expires-at"
          >
            <b-form-datepicker
              id="subscription-expires-at"
              v-model="clientData.subscription_expires_at"
              class="mb-1"
              reset-button
            />
          </b-form-group>

          <!-- Notes -->
          <b-form-group>
            <b-form-textarea
              id="clientData.notes"
              v-model="clientData.notes"
              :placeholder="$t('Notes')"
              rows="5"
            />
          </b-form-group>

          <!-- Send verify client data email -->
          <b-form-group
            :label="$t('Send verify client email')"
            label-for="verify-client-email"
          >
            <b-form-checkbox
              v-model="clientData.verify_client_email"
              switch
              inline
            />
          </b-form-group>

          <b-alert
            v-model="showSuccess"
            v-height-fade.appear
            variant="success"
            dismissible
            class="mb-0"
          >
            <div class="alert-body">
              {{ $t('Client added') }}
            </div>
          </b-alert>

          <b-alert
            v-model="showError"
            v-height-fade.appear
            variant="danger"
            dismissible
            class="mb-0"
          >
            <div class="alert-body">
              {{ errors }}
            </div>
          </b-alert>

          <!-- Form Actions -->
          <div class="d-flex mt-2">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-2"
              type="submit"
            >
              {{ $t('Add') }}
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              variant="outline-secondary"
              @click="hide"
            >
              {{ $t('Cancel') }}
            </b-button>
          </div>

        </b-form>
      </validation-observer>
    </template>
  </b-sidebar>
</template>

<script>
import {
  BAlert,
  BButton,
  BForm,
  BFormCheckbox,
  BFormDatepicker,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BFormTextarea,
  BSidebar,
} from 'bootstrap-vue'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { ref } from '@vue/composition-api'
import { alphaNum, email, required } from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import countries from '@/@fake-db/data/other/countries'
import store from '@/store'
import { heightFade } from '@core/directives/animations'

export default {
  components: {
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    vSelect,
    BAlert,

    // Form Validation
    ValidationProvider,
    ValidationObserver,
    BFormCheckbox,
    BFormDatepicker,
    BFormTextarea,
  },
  directives: {
    Ripple,
    'height-fade': heightFade,
  },
  model: {
    prop: 'isAddNewClientSidebarActive',
    event: 'update:is-add-new-user-sidebar-active',
  },
  props: {
    isAddNewUserSidebarActive: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      required,
      alphaNum,
      email,
      countries,
      showError: false,
      errors: null,
      showSuccess: false,
    }
  },
  methods: {
    handleIsTestAccountChange(isTestAccount) {
      if (isTestAccount) {
        const d = new Date()
        d.setDate(d.getDate() + 10)

        this.clientData.subscription_expires_at = `${d.getFullYear()}-${d.getMonth() + 1}-${d.getDate()} ${
          d.getHours()}:${d.getMinutes()}:${d.getSeconds()}`
      } else {
        this.clientData.subscription_expires_at = null
      }
    },
    onSubmit() {
      this.showError = false
      this.showSuccess = false
      this.errors = null

      store.dispatch('clients/addClient', this.clientData)
        .then(() => {
          this.showSuccess = true

          this.$emit('refetch-data')

          this.$emit('update:is-add-new-user-sidebar-active', false)
        })
        .catch(error => {
          console.log(error)

          this.showError = true
          this.errors = error.response.data.message
        })
    },
  },
  setup() {
    const blankClientData = {
      name: '',
      address: '',
      city: '',
      post_code: '',
      country: '',
      registration_number: '',
      vat: '',
      is_tax_payer: false,
      bank_account_number: '',
      phone: '',
      email: '',
      current_plan: null,
      is_active: true,
      is_test: false,
      is_verified: false,
      contact_person: null,
      subscription_expires_at: null,
      notes: null,
      verify_client_email: false,
      registration_source: 'app',
    }

    const clientData = ref(JSON.parse(JSON.stringify(blankClientData)))

    const resetClientData = () => {
      clientData.value = JSON.parse(JSON.stringify(blankClientData))
    }

    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation(resetClientData)

    return {
      clientData,

      refFormObserver,
      getValidationState,
      resetForm,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

#add-new-user-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
